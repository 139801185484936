import {Component, OnInit} from '@angular/core';
import {Order} from "../services/order";
import {OrdersService} from "../services/orders.service";
import {ProductsService} from "../services/products.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DebtorsService} from "../services/debtors.service";
import {DebtorSelectModalComponent} from "../debtor-select-modal/debtor-select-modal.component";
import {ActivatedRoute, Router} from "@angular/router";
import {OrderStatesService} from "../services/order-states.service";
import {OrderState} from "../services/order-state";
import {Reference} from "../services/reference";
import {forkJoin} from "rxjs";
import {ReferencesService} from "../services/references.service";
import {OrderLineEditModalComponent} from "../order-line-edit-modal/order-line-edit-modal.component";
import {Branch} from "../services/branch";
import {BranchesService} from "../services/branches.service";
import {CreditorSelectModalComponent} from "../creditor-select-modal/creditor-select-modal.component";
import {OrderLine} from "../services/order-line";
import {OrderPrintModalComponent} from "../order-print-modal/order-print-modal.component";
import {Contact} from "../services/contact";

@Component({
    selector: 'app-order-edit',
    templateUrl: './order-edit.component.html',
    styleUrls: ['./order-edit.component.scss'],
    providers: [OrdersService, OrderStatesService, ProductsService, DebtorsService, ReferencesService, BranchesService]
})
export class OrderEditComponent implements OnInit {

    orderId;

    branches: Branch[];
    orderStates: OrderState[];
    references: Reference[];

    _order: Order;

    loading = false;
    saving = false;
    copying = false;

    constructor(public ordersService: OrdersService,
                public branchesService: BranchesService,
                public orderStatesService: OrderStatesService,
                public debtorsService: DebtorsService,
                public referencesService: ReferencesService,
                public productsService: ProductsService,
                public activatedRoute: ActivatedRoute,
                public ngbModal: NgbModal,
                public router: Router) {
        this.orderId = activatedRoute.snapshot.params.orderId;
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.load(params.get('orderId'));
        });
    }

    public load(id) {
        /**
         * Unset order, it could be set already if we navigate to another product
         */
        this._order = null;

        /**
         * Set product ID and trigger a load of content
         */
        this.orderId = id;

        /**
         * Load
         */
        if (this.orderId) {
            forkJoin([
                this.branchesService.getBranches(),
                this.orderStatesService.getOrderStates(),
                this.referencesService.getReferences(),
                this.ordersService.getOrder(this.orderId, true, true, true, true, true, true, true, false, true)
            ]).subscribe(joinData => {
                this.branches = joinData[0].data;
                this.references = joinData[2].data;

                this._order = Order.fromJSON(joinData[3].data);

                // Make sure that the order has an contact
                if (this._order.contacts.length === 0) {
                    this._order.contacts.push(Contact.fromJSON({
                        name: 'Contact',
                        nickname: '',
                        full_name: '',
                        address: '',
                        zip: '',
                        city: '',
                    }));

                    this._order.contacts.push(Contact.fromJSON({
                        name: 'Delivery',
                        nickname: '',
                        full_name: '',
                        address: '',
                        zip: '',
                        city: '',
                    }));
                } else {
                 //   if (this._order.contacts.findIndex((element: Contact) => {return element.name === 'Contact'}) < 0) {
                 //       this._order.contacts.push(Contact.fromJSON({
                 //           name: 'Contact',
                 //           nickname: '',
                 //           full_name: '',
                 //           address: '',
                 //           zip: '',
                 //           city: '',
                 //       }));
                 //   }
//
                 //   if (this._order.contacts.findIndex((element: Contact) => {return element.name === 'Delivery'}) < 0) {
                 //       this._order.contacts.push(Contact.fromJSON({
                 //           name: 'Delivery',
                 //           nickname: '',
                 //           full_name: '',
                 //           address: '',
                 //           zip: '',
                 //           city: '',
                 //       }));
                 //   }
                }

                /**
                 * Get actual price to show notice if the order is not completed or frozen
                 */
                if (!this._order.isCompleted() && !this._order.isFrozen()) {
                    for (let orderLine of this._order.order_lines) {
                        if (orderLine.alternative_price_unit) {
                            orderLine.alternative_price_unit.quantity = orderLine.alternative_price_unit.getUnits(orderLine.quantity);
                        }
                    }
                }

                /**
                 * Only increase order state, remove previous states
                 */
                let orderStates = [];

                for (let orderState of joinData[1].data) {
                    if (orderState.sorting >= this._order.order_state.sorting) {
                        orderStates.push(orderState);
                    }
                }

                this.orderStates = orderStates;
            });
        } else {
            forkJoin([
                this.branchesService.getBranches(),
                this.orderStatesService.getOrderStates(),
                this.referencesService.getReferences()
            ]).subscribe(joinData => {
                let order = {
                    contacts: [
                        Contact.fromJSON({
                            name: 'Contact',
                            nickname: '',
                            full_name: '',
                            address: '',
                            zip: '',
                            city: '',
                        }),
                        Contact.fromJSON({
                            name: 'Delivery',
                            nickname: '',
                            full_name: '',
                            address: '',
                            zip: '',
                            city: '',
                        })
                    ]
                };

                for (let orderState of joinData[1].data) {
                    if (orderState.is_default) {
                        order['order_state_id'] = orderState.id;
                    }
                }

                this.branches = joinData[0].data;
                this.orderStates = joinData[1].data;
                this.references = joinData[2].data;

                this._order = Order.fromJSON(order);
            });
        }
    }

    public setDebtor() {
        const modalRef = this.ngbModal.open(DebtorSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.debtorRelatable = this._order;
        modalRef.componentInstance.parent = this;
        modalRef.componentInstance.saved = this.handleSeletedContacts;
    }

    public setCreditor() {
        const modalRef = this.ngbModal.open(CreditorSelectModalComponent, {size: 'lg'});
        modalRef.componentInstance.creditorRelatable = this._order;
        modalRef.componentInstance.parent = this;
        modalRef.componentInstance.saved = this.handleSeletedContacts;
    }

    public handleSeletedContacts(contacts) {
        for (let type of ['Contact', 'Delivery']) {
            if (contacts[type]) {
                let index = this['parent']._order.contacts.findIndex((element: Contact) => {return element.name === type});

                if (index >= 0) {
                    this['parent']._order.contacts[index] = contacts[type];
                    this['parent']._order.contacts[index].name = type;
                }
            }
        }
    }

    public copy() {
        if (confirm($localize `Are you sure?\n\nA new order will be created with identical information, but a new order number`)) {
            this.copying = true;

            this.ordersService.copyOrder(this._order).subscribe(result => {
                this.copying = false;

                this.router.navigateByUrl('/orders/' + result.data['newId']);
            });
        }
    }

    public printModal(type: string) {
        const modalRef = this.ngbModal.open(OrderPrintModalComponent, {size: 'lg'});
        modalRef.componentInstance.order = this._order;
        modalRef.componentInstance.type = type;
    }

    public addOrderLine() {
        let self = this;

        const modalRef = this.ngbModal.open(OrderLineEditModalComponent, {size: 'lg'});
        modalRef.componentInstance.order = this._order;
        modalRef.componentInstance.saved = function () {
            // @todo reload entire order could be needed here to get recalculated follow products
        }
    }

    public quantityChanged(orderLine: OrderLine) {
        /**
         * If we have a alternative price unit, then set the quantity
         */
        if (orderLine.alternative_price_unit) {
            orderLine.alternative_price_unit.quantity = orderLine.alternative_price_unit.getUnits(orderLine.quantity);
        }
    }

    public createInvoice() {
        // @todo NOT FEATURE COMPLETE, JUST ADDS A ORDER INVOICED DATE
        if (confirm($localize `Are you sure?\n\nA invoice date will be added to this order`)) {
            this.saving = true;

            this.ordersService.addOrderInvoice(this._order).subscribe(_ => {
                this.saving = false;

                this.load(this._order.id);
            });
        }
    }

    public save() {
        this.saving = true;

        if (this.orderId) {
            this.ordersService.updateOrder(this._order).subscribe(response => {
                this.saving = false;

                this.load(response.data.id);
            }, error => {
                this.saving = false;

                alert(error.error.error);
            });
        } else {
            this.ordersService.addOrder(this._order).subscribe(response => {
                this.saving = false;

                this.router.navigateByUrl('/orders/' + response.data.id);
            });
        }
    }
}
