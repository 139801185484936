import {Injectable} from '@angular/core';
import {Order} from './order';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Empty} from './empty';
import {OrderLine} from './order-line';
import {Product} from './product';
import {Dayjs} from 'dayjs';

@Injectable()
export class OrdersService {

    protected url = environment.apiBase + 'orders';

    constructor(public apiService: ApiService) {
    }

    getOrder(id: string, withOrderLines: boolean, withBranch: boolean, withDebtor: boolean, withCreditor: boolean, withOrderState: boolean, withReferences: boolean, withDates: boolean, withAuditLogs: boolean, withContacts: boolean, query?: OrdersQueryInterface): Observable<OrderResponse> {
        let url = this.url + '/' + id;

        const parameters = [];

        if (withOrderLines) {
            parameters.push('with-order-lines=1');
        }

        if (withBranch) {
            parameters.push('with-branch=1');
        }

        if (withDebtor) {
            parameters.push('with-debtor=1');
        }

        if (withCreditor) {
            parameters.push('with-creditor=1');
        }

        if (withOrderState) {
            parameters.push('with-order-state=1');
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (withDates) {
            parameters.push('with-dates=1');
        }

        if (withAuditLogs) {
            parameters.push('with-auditlogs=1');
        }

        if (withContacts) {
            parameters.push('with-contacts=1');
        }

        if (query && query.withOrderType) {
            parameters.push('with-order-type=1');
        }

        if (query && query.forCurrency) {
            parameters.push('for-currency=' + query.forCurrency);
        }

        if (query && query.forOrderDates && query.forOrderDateValue) {
            parameters.push('for-order-dates=' + query.forOrderDates);
            parameters.push('for-order-dates-value=' + query.forOrderDateValue);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<Order>(url, true, Order);
    }

    getOrderPdf(id: string, type: string): Observable<{ data: Empty, count: number, perPage: number }> {
        const url = this.url + '/' + id;

        const parameters = [
            'as-pdf=1',
            'type=' + type
        ];

        return this.apiService.get<Empty>(url + '?' + parameters.join('&'), true, Empty);
    }

    getOrders<T = Order>(page: number, limit: number, withOrderLines: boolean, withBranch: boolean, withDebtor: boolean, withCreditor: boolean, withOrderState: boolean, withReferences: boolean, hideDebtors: boolean, hideCreditors: boolean, query?: OrdersQueryInterface, filter?, itemType?:any): Observable<{ data: T[], count: number, perPage: number }> {
        let url = this.url;
        if (!itemType) {
            itemType = Order;
        }

        const parameters = [];

        parameters.push('page=' + page);

        if (query && query.searchQuery) {
            parameters.push('search-query=' + query.searchQuery);
        }

        if (query && query.dateSearchStart) {
            parameters.push('date-search-start=' + query.dateSearchStart.toISOString());
        }

        if (query && query.dateSearchEnd) {
            parameters.push('date-search-end=' + query.dateSearchEnd.toISOString());
        }

        if (query && query.forProducts) {
            parameters.push('for-products=' + query.forProducts);
        }

        if (query && query.forProductName) {
            parameters.push('for-product-name=' + query.forProductName);
        }

        if (query && query.forProductReference) {
            parameters.push('for-product-reference=' + query.forProductReference);
            parameters.push('for-product-reference-value=' + query.forProductReferenceValue);
        }

        if (query && query.forDebtor) {
            parameters.push('for-debtor=' + query.forDebtor);
            parameters.push('for-debtor-value=' + query.forDebtorValue);
        }

        if (query && query.forDebtorName) {
            parameters.push('for-debtor-name=' + query.forDebtorName);
        }

        if (query && query.forDebtorId) {
            parameters.push('for-debtor-id=' + query.forDebtorId);
        }

        if (query && query.forOrderLineFields) {
            parameters.push('for-order-line-fields=' + query.forOrderLineFields);
        }

        if (query && query.forType) {
            parameters.push('for-type=' + query.forType);
        }

        if (query && query.forState) {
            parameters.push('for-state=' + query.forState);
        }

        if (query && query.excludeState) {
            parameters.push('exclude-state=' + query.excludeState);
        }

        if (query && query.withOrderType) {
            parameters.push('with-order-type=1');
        }

        if (query && query.withContacts) {
            parameters.push('with-contacts=1');
        }

        if (query && query.withDates) {
            parameters.push('with-dates=1');
        }

        if (limit) {
            if (limit === -1) {
                parameters.push('all=1');
            } else {
                parameters.push('limit=' + limit);
            }
        }

        if (withOrderLines) {
            if (query.limitedOrderLines) {
                parameters.push('with-order-lines=limited');
            } else {
                parameters.push('with-order-lines=1');
            }
        }

        if (withBranch) {
            parameters.push('with-branch=1');
        }

        if (withDebtor) {
            parameters.push('with-debtor=1');
        }

        if (withCreditor) {
            parameters.push('with-creditor=1');
        }

        if (withOrderState) {
            parameters.push('with-order-state=1');
        }

        if (withReferences) {
            parameters.push('with-references=1');
        }

        if (hideDebtors) {
            parameters.push('hide-debtors=1');
        }

        if (hideCreditors) {
            parameters.push('hide-creditors=1');
        }

        if (query.forSelf) {
            parameters.push('for-self=1');
        }

        if (filter) {
            if (filter.sortValue && filter.sortKey) {
                parameters.push(filter.sortKey + '=' + filter.sortValue);
            }
            if (filter.sortType) {
                parameters.push('sort-type=' + filter.sortType);
            } else {
                parameters.push('sort-type=default');
            }
            if (filter.direction) {
                parameters.push('direction=' + filter.direction);
            }
            if (filter.option) {
                parameters.push('option=' + filter.option);
            }
        }

        if (query && query.forOrderDates && query.forOrderDateValue) {
            parameters.push('for-order-dates=' + query.forOrderDates);
            parameters.push('for-order-dates-value=' + query.forOrderDateValue);
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.get<T[]>(url, true, itemType);
    }

    addOrder(order: Order): Observable<OrderResponse> {
        return this.apiService.post<Order>(this.url, order, true, Order);
    }

    addOrderInvoice(order: Order): Observable<{ data: Empty, count: number, perPage: number }> {
        return this.apiService.post<Empty>(this.url + '/' + order.id + '/invoices', null, true, Empty);
    }

    copyOrder(order: Order): Observable<{ data: Empty, count: number, perPage: number }> {
        return this.apiService.post<Empty>(this.url + '/' + order.id, null, true, Empty);
    }

    updateOrder(order: Order, recalculate?: boolean): Observable<{ data: Order, count: number, perPage: number }> {

        let url = this.url + '/' + order.id;

        const parameters = [];

        if (recalculate) {
            parameters.push('recalculate-prices=1');
        }

        if (parameters.length > 0) {
            url += '?' + parameters.join('&');
        }

        return this.apiService.patch<Order>(url, order, true, Order);
    }

    updateManyOrders(orders: Order[]): Observable<{ data: Order[], count: number, perPage: number }> {
        return this.apiService.patch<Order[]>(this.url + '/edit-many', orders, true, Order);
    }


    deleteOrder(order: Order): Observable<{ data: Order, count: number, perPage: number }> {
        return this.apiService.delete<Order>(this.url + '/' + order.id, true, Order);
    }

    /**
     * @param product
     * @param quantity
     */
    generateOrderLine(product: Product, quantity: number): OrderLine {
        const orderLine = new OrderLine();
        orderLine.quantity = quantity;
        orderLine.product_id = product.id;
        orderLine.product = product;
        orderLine.price = product.adjusted_price;
        return orderLine;
    }
}
export interface OrderResponse {
 data: Order; count: number;perPage: number ;
}

export interface OrdersQueryInterface {
    limitedOrderLines?:boolean;
    excludeState?: string;
    forDebtorName?: string;
    forDebtorId?: string;
    dateSearchEnd?: Dayjs;
    dateSearchStart?: Dayjs;
    searchQuery?: string;
    forProducts?: string;
    forProductName?: string;
    forProductReference?: string;
    forProductReferenceValue?: string;
    forOrderLineFields?: string;
    forType?: string;
    forState?: string;
    forDebtor?: string;
    forDebtorValue?: string;
    withOrderType?: boolean;
    withContacts?: boolean;
    withDates?: boolean;
    sort?: string;
    direction?: 'ASC' | 'DESC';
    forSelf?: boolean;
    forCurrency?: string;
    forOrderDates?: string;
    forOrderDateValue?: string;

}
