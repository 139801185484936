import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
    language: string = 'da';

    constructor() {
    }
}
