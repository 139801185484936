import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {OfferGeneratorService} from "../services/offer-generator.service";
import {Subscription} from "rxjs";
import {IdValuesService} from "../services/themes/helms/id-values.service";

@Component({
    selector: 'app-offer-generator',
    templateUrl: './offer-generator.component.html',
    styleUrls: ['./offer-generator.component.scss']
})
export class OfferGeneratorComponent implements OnInit {

    @Input() offerSteps: any;
    title: string = $localize `Machineorder`;

    constructor(public offerService: OfferGeneratorService, public activatedRoute: ActivatedRoute, public router: Router, public idValuesService: IdValuesService) {

        if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.steps) {
            this.offerSteps = this.activatedRoute.snapshot.data.steps;
            this.title = this.activatedRoute.snapshot.data.title;
        } else {

            if (!this.offerSteps) {
                this.offerSteps = this.offerService.dummySteps;
            }
        }
        this.offerService.offerSteps = this.offerSteps;

        const rootUrl = this.activatedRoute.snapshot.url;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url === '/' + rootUrl.join('/')) {
                    this.offerService.reset();
                }
            }
        });
    }

    ngOnInit(): void {
    }

    changeStep(newStepIndex: number): void {
        if (newStepIndex <= (this.offerSteps.length - 1)) {
            this.offerService.currentStepIndex = newStepIndex;
        }
    }
}
