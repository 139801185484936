import {Component, Input, OnInit} from '@angular/core';
import {OrdersQueryInterface, OrdersService} from '../../../services/orders.service';
import {AuthService} from '../../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faInfoCircle, faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';
import {Order} from '../../../services/order';
import {IdValuesService} from '../../../services/themes/helms/id-values.service';
import {ActivatedRoute} from '@angular/router';
import {MachinesService} from '../../../services/themes/helms/machines.service';
import {Router} from '@angular/router';
import {OrderStatesService} from '../../../services/order-states.service';
import {OrderTypesService} from '../../../services/order-types.service';
import {OrderState} from '../../../services/order-state';
import {OrderType} from '../../../services/order-type';
import {Dayjs} from 'dayjs';
import {Date} from '../../../services/date';
import * as dayjs from 'dayjs';
import {DebtorsService} from '../../../services/debtors.service';
import {Debtor} from '../../../services/debtor';
import {HelmsOrder} from '../../../services/themes/helms/helms-order';
import {ReservationsService} from '../../../services/themes/helms/reservations.service';

@Component({
    selector: 'app-helms-orders',
    templateUrl: './helms-orders.component.html',
    styleUrls: ['./helms-orders.component.scss'],
    providers: [ReservationsService, DebtorsService, OrdersService, AuthService, IdValuesService, MachinesService, OrderStatesService, OrderTypesService]
})
export class HelmsOrdersComponent implements OnInit {
    @Input() disableEdit: boolean;
    @Input() embedded: boolean;
    @Input() forSelf: boolean;
    @Input() limitInfo: boolean;
    @Input() disableFilterUI: boolean;
    @Input() advancedFilter: boolean = true;
    @Input()showRekvNr: boolean;

    public active = 1;
    public faInfoCircle = faInfoCircle;
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faSort = faSort;

    @Input() orderType: string = null;
    @Input() orderState: string = null;

    orderTypes: OrderType[];
    orderStates: OrderState[];

    searchText: string;
    searchSelect = 'order_reference';

    filter = {
        sortValue: this.idValuesService.orderedDateId,
        sortKey: 'sort-by-date',
        sortType: '',
        direction: 'DESC',
        option: '',
        forSelf: false,
    };

    orders: HelmsOrder[];

    selectedOrders: HelmsOrder[] = [];

    loading = false;

    page = 1;

    limit = 25;
    count;
    perPage;

    searchDateStart: Dayjs;
    searchDateEnd: Dayjs;
    public debtors: Debtor[];
    debtorFilter: any;
    private excludeState: string;

    constructor(
        public  reservationsService: ReservationsService,
        public debtorsService: DebtorsService,
        public ordersService: OrdersService,
        public orderStatesService: OrderStatesService,
        public orderTypesService: OrderTypesService,
        public authService: AuthService,
        public ngbModal: NgbModal,
        public idValuesService: IdValuesService,
        public router: Router,
        public machinesService: MachinesService,
        public activatedRoute: ActivatedRoute) {


    }

    ngOnInit(): void {

        if ((this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.excludeState)) {
            this.excludeState  = this.activatedRoute.snapshot.data.excludeState;
        }

        if (this.forSelf || (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.forSelf)) {
            this.filter.forSelf = true;
        }

        this.activatedRoute.queryParams.subscribe(params => {
            if (!isNaN(params.page)) {
                this.page = +params.page;

                this.filter.sortKey = params['sort-key'];
                this.filter.sortValue = params['sort-value'];
                this.filter.direction = params.direction;
                this.filter.sortType = params['sort-type'];
                this.filter.option = params.option;

                this.load(this.page);
            }
        });

        this.orderStatesService.getOrderStates().subscribe(response => {
            if (this.limitInfo) {
                this.orderStates = response.data.filter((state) => {
                    // tslint:disable-next-line:max-line-length
                    return state.id === 'c17d1800-af49-4e8c-8b5f-ad33342d052b'; // @todo when limitinfo, we can only set state to hjemkommet make this settable
                });
            } else {
                this.orderStates = response.data.filter((state) => {
                    return state.id !== this.idValuesService.offerOrderStateId // vi kan ikke sætte state til Tilbud
                });
            }
        });

        this.orderTypesService.getOrderTypes().subscribe(response => {
            this.orderTypes = response.data;
        });

        this.debtorsService.getDebtors(1, -1, false, false, false, false, false).subscribe((response) => {
            this.debtors = response.data;
        });

        this.load(this.page);
    }

    search() {
        this.page = 1;
        this.load(this.page);
        this.pushState();
    }

    load(page: number, sortKey?: string, sortValue?: string, sortType?: string, flip?: boolean, option?: string): void {
        if (flip) {
            if (this.filter.sortValue === sortValue) {
                if (this.filter.direction === 'DESC') {
                    this.filter.direction = 'ASC';
                } else {
                    this.filter.direction = 'DESC';
                }
            } else {
                this.filter.direction = 'DESC';
            }
        }

        if (sortValue) {
            this.filter.sortKey = sortKey;
            this.filter.sortValue = sortValue;
        }
        if (sortType) {
            this.filter.sortType = sortType;
        }
        if (option) {
            this.filter.option = option;
        }

        this.loading = true;

        const query: OrdersQueryInterface = {
            withOrderType: true,
            withDates: true,
            limitedOrderLines: true,
        };

        if (this.orderType) {
            query.forType = this.orderType;
        }

        if (this.orderState) {
            query.forState = this.orderState;
        }

        if (this.searchText && this.searchText.length > 0) {
            switch (this.searchSelect) {
                case 'order_reference':
                    query.searchQuery = this.searchText;
                    break;
                case 'product_name':
                    query.forProductName = this.searchText;
                    break;
                case 'product_reference':
                    query.forProductReference = 'e79ff942-6260-4166-becc-bacda11b85e3'; // Produkt Nummer
                    query.forProductReferenceValue = this.searchText;
                    break;
                case 'debtor_reference':
                    query.forDebtor = '0284656a-dd5e-43aa-905f-2c90e9645cf8'; // Forhandler ID
                    query.forDebtorValue = this.searchText;
                    break;
                case 'debtor_name':
                    query.forDebtorName = this.searchText;
                    break;
            }
        }
        query.dateSearchStart = this.searchDateStart;
        query.dateSearchEnd = this.searchDateEnd;

        if (this.filter.forSelf) {
            query.forSelf = true;
        }

        if (this.debtorFilter) {
            query.forDebtorId = this.debtorFilter;
        }

        if (this.excludeState) {
            query.excludeState = this.excludeState;
        }

        this.ordersService.getOrders<HelmsOrder>(page, this.limit, true, true, true, true, true, true, false, false, query, this.filter, HelmsOrder).subscribe(response => {
            this.selectedOrders = [];
            this.orders = response.data;
            this.count = response.count;
            this.perPage = response.perPage;
            this.loading = false;
        });
    }

    pageChange(page: number): void {
        /**
         * Load pushes state because of sorting
         */
        this.load(page);
    }

    /**
     * @todo set query params without resetting base url
     */
    pushState(): void {
        if (!this.orders) {
            return;
        }

        let url = '/orders?page=' + this.page;

        if (this.filter.sortKey) {
            url += '&sort-key=' + this.filter.sortKey;
        }
        if (this.filter.sortValue) {
            url += '&sort-value=' + this.filter.sortValue;
        }
        if (this.filter.direction) {
            url += '&direction=' + this.filter.direction;
        }
        if (this.filter.sortType) {
            url += '&sort-type=' + this.filter.sortType;
        }
        if (this.filter.option) {
            url += '&option=' + this.filter.option;
        }
        history.pushState('', '', url);
    }

    deleteOrder(order: Order): void {
        if (confirm($localize`Are you sure?\n\nThe order will be deleted`)) {
            this.loading = true;

            this.ordersService.deleteOrder(order).subscribe(_ => {
                this.load(this.page);
            });
        }
    }

    onCheckboxChange(order: Order): void {
        const index = this.getSelectedOrdersIndex(order);
        if (index !== -1) {
            this.selectedOrders.splice(index, 1);
        } else {
            this.selectedOrders.push(order);
        }
    }

    getSelectedOrdersIndex(order: Order): number {
        return this.selectedOrders.findIndex((e) => {
            return e.id === order.id;
        });
    }

    orderIsSelected(order: Order): boolean {
        const index = this.selectedOrders.findIndex((e) => {
            return e.id === order.id;
        });

        return index >= 0 ? true : false;
    }

    setState(orderState: OrderState): void {

        if (this.selectedOrders.length === 0) {
            alert('fejl');
            return;
        }

        this.selectedOrders.map((order) => {
            if (orderState.sorting < order.order_state.sorting) {
                alert('Vi kan ikke gå baglens');
                return null;
            } else {
                let dateId = '';

                switch (orderState.id) {
                    case '67c6c963-28d1-4ade-a8e6-7399ea1a6600': // Bestilling
                        dateId = '9309724f-fcbd-4660-bf7a-6409e0e9f95c';
                        break;

                    case 'c17d1800-af49-4e8c-8b5f-ad33342d052b': // Hjemkommet
                        dateId = 'a6b972b2-1716-42b9-95c6-344d7a8cd636';
                        break;

                    case 'b57c5930-7f13-451f-a842-3b7bf001d682': // Leveret
                        dateId = 'f1c6a3c4-a71e-45c7-a486-3b761fa6a066';
                        break;

                    case 'ce8e0faa-6070-44c6-9a00-4a9e0feea197': // Afsluttet
                        dateId = '2581d2f3-ab95-4324-86ee-f6e34bf32b40';
                        break;
                }

                if (dateId !== '') {
                    order.dates.push(Date.fromJSON({
                        id: dateId,
                        _joinData: {
                            time: dayjs()
                        }
                    }));
                }

                order.order_state_id = orderState.id;
                return order;
            }
        });

        if (this.selectedOrders && this.selectedOrders.length > 0) {
            this.loading = true;
            this.ordersService.updateManyOrders(this.selectedOrders).subscribe(() => {
                this.loading = false;
                this.load(this.page);
            });
        }
    }

    startReserveration(order): void {
        if (confirm($localize`Are you sure?\n\nThis will create a reservation`)) {
            this.reservationsService.addReservation({order_id: order.id}).subscribe((response) => {
                alert('Reservation created');
            }, (error) => {
                console.log('Could not reserve');
            });
        }
    }
}
