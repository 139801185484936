import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OfferGeneratorService} from '../../services/offer-generator.service';
import {OrdersService} from '../../services/orders.service';
import {ProductionLinesService} from '../../helms-production-lines/services/production-lines.service';
import {IdValuesService} from '../../services/themes/helms/id-values.service';
import {OrderLine} from '../../services/order-line';

@Component({
    selector: 'app-load-offer',
    templateUrl: './load-offer.component.html',
    styleUrls: ['./load-offer.component.scss'],
    providers: [OrdersService, ProductionLinesService, IdValuesService]
})
export class LoadOfferComponent implements OnInit {

    constructor(public activatedRoute: ActivatedRoute,
                public router: Router,
                public offerGeneratorService: OfferGeneratorService,
                public ordersService: OrdersService,
                public idValuesService: IdValuesService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {

            this.ordersService.getOrder(params.get('orderId'), true, true, true, true, true, true, true, false, true, {})
                .subscribe((order) => {

                this.offerGeneratorService.reset(false);

                order.data.order_lines.forEach((orderLine) => {

                    if (orderLine.product.hasCategory({id: this.idValuesService.wheelCategoryId})) {
                        this.offerGeneratorService.changeSelectedWheel(orderLine.product, orderLine);
                    }

                    if (orderLine.product.hasCategory({id: this.idValuesService.productMachineCategoryId})) {
                        this.offerGeneratorService.changeSelectedTool(orderLine.product, orderLine);
                    }

                    if (orderLine.product.hasCategory({id: this.idValuesService.equipmentCategoryId})) {
                        if (!this.offerGeneratorService.selectedOptions) {
                            this.offerGeneratorService.selectedOptions = [];
                        }
                        orderLine.quantity = 1;
                        this.offerGeneratorService.selectedOptions.push(orderLine);

                    }
                    if (orderLine.product.hasCategory({id: this.idValuesService.toolsCategoryId})) {
                        this.offerGeneratorService.onAddClick(orderLine.product, orderLine);
                    }
                });

                // Are we based on an pipline or stock ?
                this.offerGeneratorService.selectedOrderId = order.data.parent_id ? order.data.parent_id : undefined;
                if (this.offerGeneratorService.selectedOrderId) {
                    this.offerGeneratorService.loadSelectedOrder();
                }

                this.offerGeneratorService.readyToolExists = order.data.parent_id ? true : false;

                // Make so we save the offer instead of creating a new one
                this.offerGeneratorService.newOrderId = order.data.id;

                // bring over comment
                this.offerGeneratorService.comment = order.data.comment;

                // bring over order_fields (extra lines)
                this.offerGeneratorService.orderFields = order.data.order_fields;

                if (order.data.order_type_id === '77ddc44f-a56a-4c35-b7f8-f67874cdfa17') {
                    this.router.navigate(['/', 'tool-offers', {outlets: {offer_outlet: ['select']}}]);
                } else {
                    // @todo better way of navigation to a specific step ?
                    this.offerGeneratorService.currentStepIndex = 3;
                    this.router.navigate(this.offerGeneratorService.offerSteps[3].url);
                }
            }, () => {
                alert('ukendt tilbud');
            });
        });
    }
}
