<app-table [autoSizeFirstColumn]="true" *ngIf="wheels">
    <thead>
    <tr>
        <th></th>
        <th i18n>Oversize wheels</th>
        <th class="text-right" i18n>Price in DKK</th>
    </tr>
    </thead>
    <tbody>
    <tr class="clickable" *ngFor="let product of wheels" (click)="onClick(product)">
        <td><input type="radio" [checked]="offerService.selectedWheel && offerService.selectedWheel.product_id === product.id"/></td>
        <td>
            <button class="icon-button" placement="right" [ngbTooltip]="htmlContent" triggers="manual" #t1="ngbTooltip"
                    (click)="onThumbnailClick($event, t1, product)"
                    *ngIf="product.attachments && product.attachments.length > 0"
            >
                <fa-icon [icon]="faCamera"></fa-icon>
            </button>
            {{product.name}}
        </td>
        <td class="text-right">{{product.price|currency:'DKK'}}</td>
    </tr>
    </tbody>
</app-table>
<app-loader *ngIf="!wheels"></app-loader>
<ng-template #htmlContent>
    <img src="{{activeThumbnail}}" />
</ng-template>
