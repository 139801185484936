import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TenFourOrderService} from '../../../services/themes/10-4/ten-four-order.service';
import {ActivatedRoute} from '@angular/router';
import {Order} from '../../../services/order';
import {ContactFieldPresetsService} from '../../../services/contact-field-presets.service';
import {ContactFieldPreset} from '../../../services/contact-field-preset';
import {DebtorsService} from '../../../services/debtors.service';
import {CreditorsService} from '../../../services/creditors.service';
import {Creditor} from '../../../services/creditor';
import {Debtor} from '../../../services/debtor';
import {Contact} from '../../../services/contact';
import {forkJoin} from 'rxjs';
import {OrderStatesService} from '../../../services/order-states.service';
import {OrderState} from '../../../services/order-state';
import {ReferencesService} from '../../../services/references.service';
import {Reference} from '../../../services/reference';
import {OrderField} from '../../../services/order-field';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    providers: [ContactFieldPresetsService, DebtorsService, CreditorsService, OrderStatesService, ReferencesService]
})
export class TenFourOrderComponent implements OnInit, OnDestroy {

    @Input() orderSteps: any;

    orderIdSub;
    orderSub;
    order: Order;
    orderType: 'debtor' | 'creditor' | undefined;

    creditor: Creditor;
    debtor: Debtor;

    contactFieldPreset: ContactFieldPreset;

    searching = false;

    orderStates: OrderState[];

    creditorReference: string;
    creditorSearchReference: Reference;
    debtorReference: string;
    debtorSearchReference: Reference;

    creditors: Creditor[];
    debtors: Debtor[];

    constructor(public orderService: TenFourOrderService,
                public debtorsService: DebtorsService,
                public creditorsService: CreditorsService,
                public contactFieldPresetsService: ContactFieldPresetsService,
                public orderStatesService: OrderStatesService,
                public referencesService: ReferencesService,
                public debtorService: DebtorsService,
                public creditorService: CreditorsService,
                public route: ActivatedRoute) {

        this.orderService.resetOrderAndSteps();

        this.route.paramMap.subscribe(params => {
            this.orderService.setOrderId(params.get('orderId'));

            if (!params.get('orderId')) {
                /**
                 * Adding
                 */
                forkJoin([
                    this.orderStatesService.getOrderStates(),
                    this.referencesService.getReferences()
                ]).subscribe(joinData => {
                    const order = {
                        contacts: [
                            Contact.fromJSON({
                                name: 'Contact',
                                nickname: '',
                                full_name: '',
                                address: '',
                                zip: '',
                                city: '',
                            }),
                            Contact.fromJSON({
                                name: 'Delivery',
                                nickname: '',
                                full_name: '',
                                address: '',
                                zip: '',
                                city: '',
                            })
                        ],
                        order_fields: [
                            OrderField.fromJSON({
                                id: '14d49b56-5075-4d87-9b29-4bbba0d0ff40', // Attention
                                _joinData: {
                                    //order_id: this.order.id,
                                    order_field_id: '14d49b56-5075-4d87-9b29-4bbba0d0ff40', // Attention
                                    value: '1',
                                },
                            }),
                            OrderField.fromJSON({
                                id: 'd77f5929-db59-4214-aef1-b049959387d5', // Rekv. nr.
                                _joinData: {
                                    value: '20',
                                }
                            })
                        ]
                    };

                    console.log(order.order_fields);

                    this.order = Order.fromJSON(order);

                    console.log(this.order.order_fields);

                    this.orderStates = joinData[0].data;

                    for (const reference of joinData[1].data) {
                        if (reference.is_debtor_reference) {
                            this.debtorSearchReference = reference;
                        }
                        if (reference.is_creditor_reference){
                            this.creditorSearchReference = reference;
                        }
                    }

                    this.orderService.setOrder(this.order);
                });

            } else {
                /**
                 * Editing
                 */
                this.orderService.getOrder(params.get('orderId'), true, true, true, true, true, true, true, false, true).subscribe(response => {
                    this.order = response.data;
                    if (this.order.creditor_id){
                        this.orderType = 'creditor';
                        this.orderService.isCreditor = true;
                    } else {
                        this.orderType = 'debtor';
                        this.orderService.isCreditor = false;
                    }

                    this.orderService.setOrder(this.order);

                    if (this.order.isCreditor()) {
                        this.creditorsService.getCreditor(this.order.creditor_id, false, false, false, true).subscribe(cResponse => {
                            this.creditor = cResponse.data;
                            this.orderService.client = this.creditor;
                        });
                    } else {
                        this.debtorsService.getDebtor(this.order.debtor_id, {withBalance: true}).subscribe(dResponse => {
                            this.debtor = dResponse.data;
                            this.orderService.client = this.debtor;
                        });
                    }
                });
            }
        });

        this.orderIdSub = this.orderService.currentOrderId.subscribe((orderId) => {
            this.orderSteps = this.orderService.dummySteps;
            this.orderService.changeRoute(this.orderSteps[0].url);
        });
        this.orderSub = this.orderService.currentOrder.subscribe((order) => {
            if (order){
                this.order = order;
                if (this.order.creditor_id){
                    this.orderType = 'creditor';
                    this.orderService.isCreditor = true;
                } else {
                    this.orderType = 'debtor';
                    this.orderService.isCreditor = false;
                }

                if (this.order.isCreditor()) {
                    this.creditorsService.getCreditor(this.order.creditor_id, false, false, false, true).subscribe(cResponse => {
                        this.creditor = cResponse.data;
                    });
                } else {
                    if (this.order.debtor_id) {

                    this.debtorsService.getDebtor(this.order.debtor_id, {
                        withBalance: true
                    }).subscribe(dResponse => {
                        this.debtor = dResponse.data;
                    });
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.contactFieldPresetsService.getContactFieldPreset('3f9679c7-ed2d-4dff-a163-1629db85a253', true).subscribe(response => {
            this.contactFieldPreset = response.data;
        });
    }

    ngOnDestroy(): void {
        if (this.orderIdSub) {
            this.orderIdSub.unsubscribe();
        }
        if (this.orderSub) {
            this.orderSub.unsubscribe();
        }
    }

    onChangeStep(newStepIndex: number): void {
        /* @todo bedre vising at der gemmes bør laves her! */
        this.orderService.updateOrder(this.order).subscribe((response) => {
        });
        if (newStepIndex <= (this.orderSteps.length - 1)){
            this.orderService.currentStepIndex = newStepIndex;
        }
    }

    searchDebtors(): void {
        this.searching = true;
        this.debtors = undefined;
        this.creditors = undefined;

        this.debtorService.getDebtors(1, -1, true, true, true, true, true, {
            byReferenceId: this.debtorSearchReference.id,
            byRefrenceValue: this.debtorReference
        }).subscribe(response => {
            this.debtors = response.data;
            this.searching = false;
        });
    }

    searchCreditors(): void {
        this.searching = true;
        this.debtors = undefined;
        this.creditors = undefined;

        this.creditorService.getCreditors(1, -1, true, true, {
            byReferenceId: this.creditorSearchReference.id,
            byRefrenceValue: this.creditorReference
        }).subscribe(response => {
            this.creditors = response.data;

            this.searching = false;
        });
    }

    setDebtor(debtor: Debtor): void {
        this.orderType = 'debtor';
        this.orderService.isCreditor = false;

        this.orderService.setOrder(this.order);

        this.debtor = debtor;
        this.orderService.client = this.debtor;

        this.order.debtor_id = debtor.id;
    }

    setCreditor(creditor: Creditor): void {
        this.orderType = 'creditor';
        this.orderService.isCreditor = true;

        this.orderService.setOrder(this.order);

        this.creditor = creditor;
        this.orderService.client = this.creditor;

        this.order.creditor_id = creditor.id;
    }
}
