import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import * as dayjs from 'dayjs';
import {ActivatedRoute} from "@angular/router";
import {AppStateService} from "./app-state.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: []
})
export class AppComponent implements OnInit {

    title = 'winston';
    viewPrint = false;
    private language: string;

    constructor(public authService: AuthService, public activatedRoute: ActivatedRoute, private appStateService:AppStateService) {
    }

    ngOnInit(): void {

        this.language = document.documentElement.lang || 'en';
        this.appStateService.language = this.language;
console.log(this.language);
        this.activatedRoute.paramMap.subscribe(params => {
            // Check if we need to view the page for PDF print:
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get('viewPrint') === 'true'){
                this.viewPrint = true;
            }
        });
    }

    restoreUser(): void {
        this.authService.restoreToken();
        window.location.href = '/'; // @todo make eventstream/listner to post changes to impersonation status
    }
}
