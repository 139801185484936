<table style="width: 100%;">
    <thead>
    <tr>
        <td>
            <div class="print-header-space">&nbsp;</div>
        </td>
    </tr>
    </thead>
    <tbody>
    <tr>
        <td>
            <div class="content">

                <div [className]="viewPrint ? 'helms-print-friendly-order  helms-print-friendly-order--print position-relative' : 'helms-print-friendly-order'" *ngIf="!loading">
                    <h1 class="heading" *ngIf="!viewPrint" i18n>Generate offer</h1>
                    <div class="row">
                        <div class="col" *ngIf="!viewPrint">
                            <label class="mb-2  d-block  mw-100">
                                <strong><span i18n>Name</span>:</strong><br />
                                <input type="text" class="form-control" [(ngModel)]="printFriendlyInfo.name" i18n-placeholder placeholder="e.g. Name" *ngIf="!viewPrint"/>
                            </label>
                            <label class="mb-4  d-block  mw-100">
                                <strong><span i18n>Address</span>:</strong><br />
                                <textarea rows="4" class="form-control mb-0" [(ngModel)]="printFriendlyInfo.address_field"></textarea>
                                <!--                <input type="text" class="form-control mb-1" [(ngModel)]="printFriendlyInfo.address" i18n-placeholder placeholder="e.g. Street 24"/>-->
                                <!--                <div class="row">-->
                                <!--                    <div class="col">-->
                                <!--                        <input type="text" class="form-control" [(ngModel)]="printFriendlyInfo.zip" i18n-placeholder placeholder="e.g. 7400"/>-->
                                <!--                    </div>-->
                                <!--                    <div class="col-8">-->
                                <!--                        <input type="text" class="form-control" [(ngModel)]="printFriendlyInfo.city" i18n-placeholder placeholder="e.g. Herning"/>-->
                                <!--                    </div>-->
                                <!--                </div>-->
                            </label>
                        </div>
                        <div class="col  mb-4  text-small" *ngIf="viewPrint">
                            <p>
                                <strong>{{printFriendlyInfo.name}}</strong><br />
                                <span style="white-space: pre-line">{{printFriendlyInfo.address_field}}</span>
                            </p>

                            <dl>
                                <dt *ngIf="printFriendlyInfo.reknr"><span i18n>Req. no.</span>:</dt>
                                <dd *ngIf="printFriendlyInfo.reknr">{{printFriendlyInfo.reknr}}</dd>
                                <dt *ngIf="printFriendlyInfo.date && printFriendlyInfo.date.isValid()"><span i18n>Date</span>:</dt>
                                <dd *ngIf="printFriendlyInfo.date && printFriendlyInfo.date.isValid()">{{printFriendlyInfo.date.format('D/M-YYYY')}}</dd>
                            </dl>
                        </div>

                        <div [className]="productImage ? 'col-7' : 'col'" style="white-space: pre-wrap;">
                            <h1 class="heading  h2  mb-2"><span i18n>Offer</span></h1>
                            <p *ngIf="printFriendlyInfo.offerText && printFriendlyInfo.offerText !==''">{{printFriendlyInfo.offerText}}</p>
                        </div>
                        <div class="col-3" *ngIf="productImage">
                            <img [src]="productImage" style="max-width: 100%;" [className]="'img-responsive  mb-4'" />
                        </div>

                        <div class="col" *ngIf="!viewPrint">
                            <label class="mb-2  d-block  mw-100">
                                <strong i18n>Req. no.</strong><br />
                                <input type="text" class="form-control" [(ngModel)]="printFriendlyInfo.reknr" i18n-placeholder placeholder="XXXX-XXXX-XXXX-XXXX"/>
                            </label>
                            <label class="mb-4  d-block  mw-100">
                                <strong><span i18n>Date</span>:</strong><br/>
                                <input type="date" class="form-control" [(ngModel)]="date" (focus)="handleDate()"/>
                            </label>
                        </div>
                    </div>
                    <div class="row" *ngIf="!viewPrint">
                        <label class="col-form-label col">
                            <strong><span i18n>Currency</span>:</strong>
                            <select class="form-control mb-2" [(ngModel)]="printFriendlyInfo.currencyId" (ngModelChange)="onCurrencyChange()">
                                <option *ngFor="let currency of currencies"
                                        [ngValue]="currency.id">{{currency.name}}</option>
                            </select>
                        </label>
                        <div class="col  d-flex  align-items-center">
                            <div style="height: 34px" class="mt-3" *ngIf="delivery">
                                <app-switch [checked]="printFriendlyInfo.includeDeliveryCosts" [smallSwitch]="false" (sliderEvent)="onDeliveryCostChange($event)">
                                    <strong><span i18n>Include delivery cost</span></strong>
                                </app-switch>
                            </div>

                        </div>
                    </div>
                    <div class="row" *ngIf="!viewPrint">
                        <label class="col-form-label col">
                            <strong><span i18n>Hide equipment prices</span>:</strong>
                            <div class="form-group">
                                <input type="checkbox" id="publish" [(ngModel)]="printFriendlyInfo.hide_equipment_prices" name="publish">
                            </div>
                        </label>
                    </div>
                    <div class="row" *ngIf="!viewPrint">
                        <label class="col-form-label col">
                            <strong><span i18n>Offer</span>:</strong>
                            <textarea rows="4" class="form-control mb-0" [(ngModel)]="printFriendlyInfo.offerText"></textarea>
                        </label>
                    </div>

                    <div class="row">
                        <div class="col">
                            <app-loader class="text-muted" *ngIf="currencyLoading" i18n>Loading...</app-loader>

                            <strong class="mb-2" *ngIf="!viewPrint"><span i18n>Machines & products</span>:</strong>
                            <app-table style="width: 100%;" *ngIf="!currencyLoading">
                                <thead>
                                <tr>
                                    <th style="width: 75%"> </th>
                                    <th style="width: 25%" class="text-right"><span i18n>Amount</span></th>
                                    <th style="width: 15%" class="text-right"><span i18n>Price</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-template ngFor let-groupData [ngForOf]="groupedData" let-i="index">
                                    <tr>
                                        <th colspan="3">{{groupData.group}}</th>
                                    </tr>
                                    <ng-template ngFor let-orderLine [ngForOf]="groupData.order_lines" let-j="index">
                                        <ng-container *ngIf="i === 0">
                                            <ng-container *ngIf="!orderLine.product.prices[0].price_original">
                                                <tr>
                                                    <td>{{orderLine.product.name}}</td>
                                                    <td class="text-right">{{orderLine.quantity}}</td>
                                                    <td class="text-right">
                                                        {{ (order?.is_exchanged ? orderLine.exch_price : orderLine.price) |currency:this.currency.name }}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                            <ng-container *ngIf="orderLine.product.prices[0].price_original">
                                                <tr>
                                                    <td>{{orderLine.product.name}}</td>
                                                    <td class="text-right">{{orderLine.quantity}}</td>
                                                    <td class="text-right">
                                                        {{ orderLine.product.prices[0].price_original |currency:this.currency.name }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rabat</td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        <ng-container *ngIf="!printFriendlyInfo.hide_equipment_prices || printFriendlyInfo.hide_equipment_prices && i === 0">
                                                            <span>-{{ (orderLine.product.prices[0].price_original - orderLine.product.prices[0].price)|currency:this.currency.name }}</span>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>KAMPAGNEPRIS</td>
                                                    <td></td>
                                                    <td class="text-right">
                                                        <ng-container *ngIf="!printFriendlyInfo.hide_equipment_prices || printFriendlyInfo.hide_equipment_prices && i === 0">
                                                            {{ (order?.is_exchanged ? orderLine.exch_price : orderLine.price) |currency:this.currency.name }}
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="i > 0">
                                            <tr>
                                                <td>{{orderLine.product.name}}</td>
                                                <td class="text-right">{{orderLine.quantity}}</td>
                                                <td class="text-right">
                                                    <ng-container *ngIf="!printFriendlyInfo.hide_equipment_prices">
                                                        {{ (order?.is_exchanged ? orderLine.exch_price : orderLine.price) |currency:this.currency.name }}
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                                <ng-container>
                                    <tr>
                                        <th colspan="3"><span i18n>Others</span></th>
                                    </tr>
                                    <tr *ngFor="let customLine of printFriendlyInfo.customLines">
                                        <td>{{customLine.value}}</td>
                                        <td></td>
                                        <td class="text-right">{{customLine.cost|currency:this.currency.name}}</td>
                                    </tr>
                                </ng-container>
                                <tr [className]="!viewPrint ? 'font-weight-bold bg-dark text-white text-uppercase' : ''">
                                    <td><strong i18n>Total price with tools</strong></td>
                                    <td></td>
                                    <td class="text-right"><strong>{{ (printFriendlyInfo.orderTotal + printFriendlyInfo.customPrice) | currency:this.currency.name:'':null }}</strong></td>
                                </tr>
                                </tbody>
                            </app-table>
                        </div>
                    </div>
                    <div class="row  mb-4" *ngIf="!viewPrint">
                        <ng-container *ngFor="let customLine of printFriendlyInfo.customLines; let i = index">
                            <ng-container *ngIf="!customLine.isDelivery">
                                <div class="col-9  mb-1">
                                    <input [disabled]="customLine.isDelivery" type="text" class="form-control"
                                           i18n-placeholder placeholder="e.g. discount, additional tools" [(ngModel)]="customLine.value"/>
                                </div>
                                <div class="col-3  mb-1">
                                    <div class="d-flex">
                                        <input [disabled]="customLine.isDelivery" type="number" class="form-control"
                                               [(ngModel)]="customLine.cost" (focus)="onCustomPriceChange()"
                                               (keyup)="onCustomPriceChange()"/>

                                        <button class="btn  btn-danger  ml-2" (click)="onRemoveRowClick(i)">
                                            <fa-icon [icon]="faTrashCan"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <div class="col-12  text-center">
                            <button class="btn  btn-sm  btn-success  d-inline-block  mt-2" (click)="onAddRowClick(false)">
                                +
                                <span *ngIf="printFriendlyInfo.customLines.length > 0" i18n>Add another row</span>
                                <span *ngIf="printFriendlyInfo.customLines.length <= 0" i18n>Add row</span>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="!viewPrint">
                        <label class="col-form-label col">
                            <strong i18n>Comments & other:</strong>
                            <textarea class="form-control" rows="8" [(ngModel)]="printFriendlyInfo.comments"></textarea>
                        </label>
                    </div>
                    <div class="row" *ngIf="viewPrint">
                        <div class="col" style="white-space: pre-wrap;">
                            <p>{{printFriendlyInfo.comments}}</p>
                        </div>
                    </div>
                    <div class="row" *ngIf="!viewPrint">
                        <div class="col d-flex align-content-between justify-content-between">


                            <a [routerLink]="['/', 'offers', {outlets: {offer_outlet: ['load',order.id]}}]" class="btn btn-success float-right">
                                &nbsp;<span i18n>Resume order</span>
                            </a>

                            <button class="btn  btn-secondary" (click)="onSaveOffer()" [disabled]="savedOffer || savingOffer">
                                <ng-container *ngIf="!savingOffer">
                                    <fa-icon [icon]="savedOffer ? faCheck : faDisk"></fa-icon> {{savedOffer ? 'Tilbud er gemt!' : 'Gem Tilbud'}} <!-- @todo -->
                                </ng-container>
                                <ng-container *ngIf="savingOffer">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only" i18n>Loading...</span>
                                </ng-container>
                            </button>

                            <button class="btn btn-success" (click)="onGenerateClick()" [disabled]="generatingPdf" *ngIf="!pdfBlob">
                                <ng-container *ngIf="!generatingPdf && !pdfBlob">
                                    <fa-icon [icon]="faPDF"></fa-icon>&nbsp;<span i18n>Generate offer</span>
                                </ng-container>
                                <ng-container *ngIf="generatingPdf && !pdfBlob">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only" i18n>Loading...</span>
                                </ng-container>
                            </button>

                        </div>
                    </div>


                </div>

            </div>
        </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
        <td>
            <div class="print-footer-space">&nbsp;</div>
        </td>
    </tr>
    </tfoot>
</table>

<div class="print-header w-100" *ngIf="viewPrint">
    <div class="p-4  mb-4 w-100" style="background-color: #e31736;" >
        <img src="../../../assets/themes/helms/assets/png/schaffer-logo.png" class="header__logo-img  img-responsive" />
    </div>
</div>
<div class="print-footer w-100 border-top p-4" *ngIf="viewPrint">
    <div class="d-flex justify-content-between align-items-end p-2 bg-white" style="gap: 1rem;">
        <img *ngIf="order.debtor.getThumbnail('product_list_preview')"
             [src]="order.debtor.getThumbnail('product_list_preview')" style="max-width: 100%;"
             [className]="'img-responsive'"/><br/>
        <p>
            {{order?.debtor.name}}<br/>
            <ng-template ngFor let-debtorContact [ngForOf]="order?.debtor.contacts">
                <ng-container *ngIf="debtorContact.name">{{ debtorContact.name }} <small>{{ debtorContact.nickname }}</small><br/></ng-container>
                {{ debtorContact.address }} -                 {{ debtorContact.zip }} - {{ debtorContact.city }}
                <ng-template ngFor let-contactField [ngForOf]="debtorContact.contact_fields">
                    {{ contactField.name }}: {{ contactField._joinData.value }}<br>
                </ng-template>
            </ng-template>
        </p>
    </div>
</div>

<app-loader *ngIf="loading"></app-loader>
