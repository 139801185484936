import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppStateService} from "./app-state.service";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

    constructor(private appStateService: AppStateService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let currentLocale;
        switch (this.appStateService.language) {
            case 'se':
                currentLocale = 'sv_SE';
                break;
            case 'da':
                currentLocale = 'da_DK';
                break;

        }

        console.log(currentLocale);
        // Get the current locale of the app. (Replace this with actual locale logic.)

        // Clone the HTTP request and add the Accept-Language header
        const modifiedRequest = request.clone({
            setHeaders: {
                'X-Winston-Locale': currentLocale,
            },
        });

        // Pass the modified request to the next handler
        return next.handle(modifiedRequest);
    }
}
