<ol class="stepstones  stepstones--timeline" *ngIf="showStepStones">
    <ng-container *ngFor="let item of stepStones; let i = index">
        <li [className]="'stepstones__item' + (i <= activeStepIndex ? '  stepstones__item--active' : '')">
            <ng-container *ngIf="i <= activeStepIndex">
                <a (click)="onTimelineClick(i)" [routerLink]="item.url" [className]="'stepstones__link' + (i <= activeStepIndex ? '  stepstones__link--active' : '')">
                    {{item.title}}
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" height="100%">
                    <polygon points="100,50 0,100 0,0"/>
                </svg>
            </ng-container>
            <ng-container *ngIf="i > activeStepIndex">
                <span [className]="'stepstones__link' + (i <= activeStepIndex ? '  stepstones__link--active' : '')">
                    {{item.title}}
                </span>
            </ng-container>
        </li>
    </ng-container>
</ol>
<ng-container *ngIf="showStepStoneNavigation">
    <div class="stepstones  stepstones--navigation  mt-3  pb-5">
        <button (click)="onPrevClick(stepStones[activeStepIndex - 1])" class="btn  btn-secondary stepstones__button  stepstones__button--prev" *ngIf="activeStepIndex > 0">
            <ng-container i18n>Previous</ng-container>
        </button>

        <button *ngIf="(activeStepIndex < (stepStones.length - 1)) && !(stepStones[activeStepIndex].hideNextButton)" (click)="onNextClick(stepStones[activeStepIndex + 1])" [disabled]="canGoToNextStep ? false : true" class="btn  btn-primary  stepstones__button  stepstones__button--next">
            <ng-container i18n>Next</ng-container>
        </button>
    </div>

</ng-container>
