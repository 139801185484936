import {Component, Input, OnInit} from '@angular/core';
import {OfferGeneratorService} from '../../services/offer-generator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Order} from '../../services/order';
import {OrderResponse, OrdersService} from '../../services/orders.service';
import {ProductionLinesService} from '../../helms-production-lines/services/production-lines.service';
import {ProductionLineSlot} from '../../helms-production-lines/services/production-line-slot';
import {
    faArrowDownUpAcrossLine,
    faArrowsUpDown,
    faCalendar,
    faChevronLeft,
    faChevronUp,
    faClipboardList,
    faClockRotateLeft,
    faCog,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import {IdValuesService} from '../../services/themes/helms/id-values.service';
import {forkJoin, of} from "rxjs";
import structuredClone from 'core-js/actual/structured-clone';

@Component({
    selector: 'app-confirm-order',
    templateUrl: './confirm-order.component.html',
    styleUrls: ['./confirm-order.component.scss'],
    providers: [OrdersService, ProductionLinesService, IdValuesService]
})
export class ConfirmOrderComponent implements OnInit {

    preview: boolean;
    order: Order;
    productionLineSlot: ProductionLineSlot;

    mode: 'machines' | 'tools' = 'machines';
    totals = {
        price: 0,
        price_adjusted: 0,
        savings: 0
    };
    processing: boolean;
    public orderData: Order;
    faCalendar = faCalendar;

    constructor(
        public idValuesService: IdValuesService,
        public productionLinesService: ProductionLinesService,
        public offerService: OfferGeneratorService,
        public router: Router,
        public ordersService: OrdersService,
        public activatedroute: ActivatedRoute) {

        dayjs.extend(advancedFormat);
        dayjs.extend(isoWeek);

        if (this.activatedroute.snapshot.data && this.activatedroute.snapshot.data.preview) {
            this.preview = true;
        }
        if (this.activatedroute.snapshot.data && this.activatedroute.snapshot.data.mode) {
            this.mode = this.activatedroute.snapshot.data.mode;
        }
    }

    ngOnInit(): void {
        if (!this.offerService.selectedTool && this.mode === 'machines') {
            this.router.navigate(['/', 'offers', {outlets: {offer_outlet: ['tool-orders']}}]);
        } else {
            this.offerService.pausenext = false;
            this.getTotalPrice();
            this.buildOrder();

        }
    }



    getTotalPrice(): void {

        let price = 0;
        let price_adjusted = 0;
        const savings = 0;

        if (this.offerService.selectedTool) {
            price = price + this.offerService.selectedTool.price;
        }
        this.offerService.selectedOptions.map((el) => {
            price += el.price * el.quantity;
        });
        if (this.offerService.selectedWheel) {
            price = price + this.offerService.selectedWheel.price;
        }

        this.offerService.selectedExtraTools.map((el) => {
            price += el.price * el.quantity;
        });

        this.totals.price = price;

        if (this.offerService.selectedTool) {
            price_adjusted = price_adjusted + this.offerService.selectedTool.price_adjusted;
        }
        this.offerService.selectedOptions.map((el) => {
            price_adjusted += el.price_adjusted * el.quantity;
        });
        if (this.offerService.selectedWheel) {
            price_adjusted = price_adjusted + this.offerService.selectedWheel.price_adjusted;
        }

        this.offerService.selectedExtraTools.map((el) => {
            price_adjusted += el.price_adjusted * el.quantity;
        });

        this.totals.price_adjusted = price_adjusted;

        this.totals.savings = price - price_adjusted;
    }

    buildOrder(): void {

        const preFetch = [
            this.offerService.selectedOrderId ? this.ordersService.getOrder(this.offerService.selectedOrderId, false, false, false, false, false, false, false, false, false) : of(null),
            this.offerService.newOrderId ? this.ordersService.getOrder(this.offerService.newOrderId, false, false, false, false, false, false, false, false, false) : of(null)
        ];

        forkJoin(preFetch).subscribe((results) => {

            this.orderData = new Order();

            this.orderData.comment = this.offerService.comment;
            let result;
            if (results[0]){
                result = results[0];
            }

            if (results[1]) {
                const offer = results[1];
                this.orderData.order_fields = offer.data.order_fields;
            }

            // tslint:disable-next-line:max-line-length
            this.orderData.branch_id = this.idValuesService.branchId; // @todo Attach debitor to branch see https://git.hassel-it.dk/10-4/winston/-/issues/116

            if (this.mode === 'tools') {
                this.orderData.order_type_id = '77ddc44f-a56a-4c35-b7f8-f67874cdfa17'; // redskab ordre
            } else {
                if (this.offerService.selectedOrderId) {
                    if (result && result.data && result.data.order_state_id === this.idValuesService.pipelineOrderStateId) {
                        this.orderData.order_type_id = 'cf0d5dfd-bcaf-41a4-b24c-723c82fa8ebf'; // fra pipeline
                    } else {
                        this.orderData.order_type_id = 'be969ca9-d5f0-4eec-bdc7-dab679ea7979'; // fra lager
                    }
                    this.orderData.parent_id = this.offerService.selectedOrderId;
                } else {
                    this.orderData.order_type_id = '5b394b80-e003-44bf-a5cb-a805121caa9b'; // konfigureret
                }
            }

            let i = 0;

            if (this.offerService.selectedTool) {
                this.offerService.selectedTool.sorting = i;
                this.orderData.addOrderLine(this.offerService.selectedTool);
            }

            if (this.offerService.selectedWheel) {
                i++;
                this.offerService.selectedWheel.sorting = i;
                this.orderData.addOrderLine(this.offerService.selectedWheel);
            }

            this.offerService.selectedOptions.forEach((el) => {
                i++;
                el.sorting = i;
                this.orderData.addOrderLine(el);
            });

            this.offerService.selectedExtraTools.forEach((el) => {
                i++;
                el.sorting = i;
                this.orderData.addOrderLine(el);
            });

            // @todo samlet lookup for ordretype ids?
            // @todo needs to be more rxjs (don't nest subscribtions)
            if (this.orderData.order_type_id === '5b394b80-e003-44bf-a5cb-a805121caa9b') {
                this.productionLinesService.emulateProductionLineSlot(this.offerService.selectedTool.product.id).subscribe((respone) => {
                    this.productionLineSlot = respone.data;
                }, (error) => {
                    this.productionLineSlot = null;
                });
            }

            const sanitizedOrderData = structuredClone(this.orderData);
            sanitizedOrderData.order_lines.forEach((orderline) => {
                delete orderline.product;
            });
        });
    }

    placeOrder(): void {
        this.orderData.order_state_id = this.idValuesService.orderedOrderStateId;
        this.processing = true;
        if (this.offerService.newOrderId) {
            this.orderData.id = this.offerService.newOrderId;

            const sanitizedOrderData = structuredClone(this.orderData);
            sanitizedOrderData.order_lines.forEach((orderline) => {
                delete orderline.product;
            });

            this.ordersService.updateOrder(sanitizedOrderData, true).subscribe(response => {
                this.order = response.data;
                this.offerService.setComplete();
                this.processing = false;
                this.redirectComplete(this.order);
            });
        } else {

            const sanitizedOrderData = structuredClone(this.orderData);
            sanitizedOrderData.order_lines.forEach((orderline) => {
                delete orderline.product;
            });

            this.ordersService.addOrder(sanitizedOrderData).subscribe(response => {
                this.order = response.data;
                this.offerService.setComplete();
                this.processing = false;
                this.redirectComplete(this.order);
            });
        }
    }

    redirectComplete(order): void {
        if (this.orderData.parent_id) {
            this.router.navigateByUrl('/orders/' + this.orderData.parent_id);
        } else {
            this.router.navigateByUrl('/orders/' + order.id);
        }
    }

    printOffer(): void {
        this.orderData.order_state_id = this.idValuesService.offerOrderStateId;
        this.processing = true;
        if (this.offerService.newOrderId) {
            this.orderData.id = this.offerService.newOrderId;

            const sanitizedOrderData = structuredClone(this.orderData);
            sanitizedOrderData.order_lines.forEach((orderline) => {
                delete orderline.product;
            });

            this.ordersService.updateOrder(sanitizedOrderData, true).subscribe(response => {
                this.order = response.data;
                this.processing = false;
                this.router.navigate(['/', 'custom', 'print-friendly-order', this.orderData.id]);
                // window.open('/custom/print-friendly-order/' + this.order.id);
            });
        } else {

            const sanitizedOrderData = structuredClone(this.orderData);
            sanitizedOrderData.order_lines.forEach((orderline) => {
                delete orderline.product;
            });

            this.ordersService.addOrder(sanitizedOrderData).subscribe(response => {
                this.order = response.data;
                this.processing = false;
                this.offerService.newOrderId = this.order.id;
                // window.open('/custom/print-friendly-order/' + this.order.id);
                this.router.navigate(['/', 'custom', 'print-friendly-order', this.order.id]);
            });
        }
    }

    updateComment(comment) {
        this.orderData.comment = comment;
    }
}
