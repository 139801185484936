<div class="login">
    <div class="login__wrapper">
        <div class="login__header">
            <ng-container *ngIf="!enviroment.customer">
                <h1 class="logo  logo--text  logo--centerized">Winston</h1>
            </ng-container>
            <ng-container *ngIf="enviroment.customer">
                <img class="logo  logo--centerized" [src]="logo" />
            </ng-container>
        </div>

        <div *ngIf="hasError" class="alert  alert-danger">
            Forkert Brugernavn og/eller Kodeord
        </div>

        <form (ngSubmit)="onSubmit()">

            <div *ngIf="!challenge" class="form-group">
                <label for="user-email" i18n>Email address</label>
                <input type="email" class="form-control" [ngClass]="{ 'is-invalid': hasError }" id="user-email" autocomplete="username" [(ngModel)]="email" name="email" [disabled]="logginIn" required>
            </div>

            <div *ngIf="!challenge" class="form-group">
                <label for="user-password" i18n>Password</label>
                <input type="password" class="form-control" [ngClass]="{ 'is-invalid': hasError }" id="user-password" autocomplete="current-password" [(ngModel)]="password" name="password" [disabled]="logginIn" required>
            </div>

            <div class="login__extras" *ngIf="challenge">

                <div  class="form-group">
                    <label for="user-two-factor-authentication-password" i18n>One time password</label>
                    <input type="text" maxlength="6" id="user-two-factor-authentication-password" class="form-control" [ngClass]="{ 'is-invalid': hasError }" autocomplete="off" [(ngModel)]="two_factor_authentication_password" name="two_factor_authentication_password" [disabled]="logginIn">
                </div>

                <!-- @todo not actually functional
                <div class="form-group  form-check">
                    <input type="checkbox" class="form-check-input" id="remember-me" [disabled]="logginIn">
                    <label class="form-check-label" for="remember-me">Remember me</label>
                </div>
                -->
    


            </div>

            <div class="login__extras" *ngIf="!challenge">
            <div class="form-group">
                <a routerLink="/forgot-password" i18n>Did you forget your password ?</a>
            </div>
            </div>
            <button *ngIf="!logginIn" type="submit" class="login__button  btn  btn-primary  btn-lg">Login</button>
            <button *ngIf="logginIn" type="submit" class="login__button  login__button--verifying  btn  btn-primary  btn-lg" [disabled]="true">
                <div class="spinner-border  spinner-border-md  text-light" role="status">
                    <span class="sr-only">Godkender...</span>
                </div>
            </button>

        </form>
    </div>
</div>
